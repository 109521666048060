
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';
import proceduresModule from '@/store/modules/proceduresModule';

@Component({
  components: {},
})
export default class SaveProcedureStep extends mixins(DataLoading) {
  @Prop({ required: true }) procedureItem!: any;

  @Prop({ required: true }) stepItem!: any;

  @Prop({ required: false }) editing!: boolean;

  get responses() {
    return [
      'Okay as-is',
      'Cleaned',
      'Replaced',
      'Reset',
      'Repaired',
      'Changed setpoint',
    ];
  }

  actionShown = 'stepEdit';

  previousStepOrder = '';

  wellSearch = '';

  activeExclusion = '';

  stepValid = false;

  procedure = null as any;

  step = null as any;

  created() {
    this.procedure = JSON.parse(JSON.stringify(this.procedureItem));
    this.step = this.procedure.steps.find(i => i.id === this.stepItem.id);
    this.stepValid = Boolean(this.step.text);
  }

  async save() {
    // this.stopDataLoading();
	// for (var x = 0; x < this.procedure.steps.length; x++) {
	// 	if(this.procedure.steps[x].id == this.stepItem.id) {
	// 		this.procedure.steps[x].text = "82j2"
	// 		this.procedure.steps[x].title = "zxncvb"
	// 	}
	// }
	// this.procedure.steps.find(i => i.id === this.stepItem.id)

	this.step.title = this.step.title
	this.step.text = this.step.text
    if (!this.stepValid) {
      return;
    }
    this.reorderSteps();


    if (this.editing) {
      await proceduresModule.editActiveProcedure(this.procedure);
      await proceduresModule.setActiveProcedure(this.procedure);

      this.$emit('save-step', this.procedure.steps);
    }
    this.$emit('save-step', this.procedure.steps);
    // this.stopDataLoading();
  }

  deleteStep() {
	//   this.$emit('save-step', this.procedure.steps);
  }

  reorderSteps(simple = false) {
    if (!simple) {
      const itemToReplace = this.procedure.steps.find(
        (i) => i.id !== this.step.id && i.order === this.step.order,
      );
      if (!itemToReplace) {
        return;
      }
      itemToReplace.order += 1;
      this.procedure.steps.sort((a, b) => a.order - b.order);
    }
    this.procedure.steps.forEach((i) => {
      if (i.order > this.step.order || simple) {
        Object.assign(i, { order: this.procedure.steps.indexOf(i) + 1 });
      }
    });

  }


  async handleStepDiscardDeleteButton(step) {
	  if (this.editing) {
		  const stepIndex = this.procedure.steps.indexOf(step);
		  this.procedure.steps.splice(stepIndex, 1);
		  await proceduresModule.editActiveProcedure(this.procedure);
      	  await proceduresModule.setActiveProcedure(this.procedure);
		  this.$emit('save-step', this.procedure.steps);
	  } else {
		  close()
	  }
  }

  async close() {
    const stepIndex = this.procedure.steps.indexOf(this.step);
    this.procedure.steps.splice(stepIndex, 1);

    this.reorderSteps(true);

    if (this.editing && !this.step.temporary) {
      // await this.$store.dispatch(EDIT_PROCEDURE_ITEM, this.procedure);
    }

    // this.$eventBus.$emit('procedureSaveStep.modal.toggle', {
    //   isVisible: false,
    //   procedure: null,
    //   step: null,
    // });

    this.$emit('save-step');
  }

  toggleExceptionView() {
    this.actionShown = 'exclusionEdit';
    this.wellSearch = '';
  }

  selectWell(well) {
    this.wellSearch = well;
  }

  async addException(item) {
    this.step.exclusions.push(item);
    this.wellSearch = '';
    this.activeExclusion = '';
    this.actionShown = 'stepEdit';
    // const undo = await this.$showSystemElement({
    //   snackBarText: `${ item } excluded`,
    //   closable: false,
    //   showUndo: true,
    //   type: 'showSystemSnackbar',
    // });
    // if (undo) {
    //   this.step.exclusions.splice(-1, 1);
    // }
  }

  resetActiveExclusion() {
    this.activeExclusion = '';
  }

  removeExclusion(exclusion) {
    const index = this.step.exclusions.indexOf(exclusion);
    this.step.exclusions.splice(index, 1);
  }
}
